import Header from "@components/auth/header";
import LandingLayout from "@components/layouts/LandingLayout";
import useGlobalState from "@hooks/useGlobalState";
import useSignup from "@hooks/useSignup";
import MBCGLogoDark from "@icons/branding/mbcg-logo-stacked-bgd.svg";
import MBCGLogoLight from "@icons/branding/mbcg-logo-stacked-bgl.svg";
import WBCGLogoDark from "@icons/branding/wbcg-logo-stacked-bgd.svg";
import WBCGLogoLight from "@icons/branding/wbcg-logo-stacked-bgl.svg";
import { useTheme } from "next-themes";
import { Input, InputErrorText, InputRow } from "@styles/inputs.styled";
import { media, pxToRem } from "@styles/styleUtils";
import { useFormik } from "formik";
import { ReactElement, useState } from "react";
import styled from "styled-components";
import { ringsideCondensed } from "../lib/fonts";
import { MMLButton } from "@f2p-mml-frontends/mml-components";

const SignUpContentContainer = styled.div`
  margin: 120px auto 0 auto;
  width: 100vw;
  max-width: 787px;

  ${media.mvp} {
    width: 327px;
    margin: 46px auto 0 auto;
  }

  h2 {
    text-align: center;
    font-size: ${pxToRem(42)};
    font-stretch: condensed;
    font-style: normal;
    font-weight: ${ringsideCondensed.style.fontWeight};
    letter-spacing: -0.5px;
    line-height: normal;
    margin: 0 0 ${pxToRem(7)} 0;
    text-transform: uppercase;

    ${media.mvp} {
      font-style: normal;
      font-size: ${pxToRem(28)};
      line-height: ${pxToRem(28)};
      text-align: center;
      margin: 0 0 ${pxToRem(17)} 0;
    }
  }

  p {
    font-size: ${pxToRem(14)};
    line-height: normal;
    text-align: center;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    font-weight: 500;
    margin: 0 0 ${pxToRem(32)} 0;
    width: 100%;

    ${media.mvp} {
      max-width: ${pxToRem(272)};
      font-style: normal;
      font-size: ${pxToRem(12)};
      text-align: center;
      letter-spacing: 0.4px;
      text-transform: uppercase;
      margin: 0 auto ${pxToRem(39)} auto;

      &.is-success {
        max-width: ${pxToRem(319)};
      }
    }
  }
`;

const BCGLogoContainer = styled.div<{ $hideWbcg: boolean }>`
  display: flex;
  justify-content: center;
  column-gap: ${pxToRem(52)};
  margin-bottom: ${pxToRem(60)};

  ${media.mvp} {
    flex-direction: column;
    align-items: center;
    row-gap: 32px;
  }

  .bcg-logo {
    width: 284px;
    height: 182px;

    width: ${({ $hideWbcg }) => $hideWbcg && "340px"};
    height: ${({ $hideWbcg }) => $hideWbcg && "217px"};

    ${media.mvp} {
      width: 230px;
      height: 150px;
    }
  }
`;

const StyledInputError = styled(InputErrorText)`
  position: absolute;
  bottom: -17px;

  ${media.mvp} {
    position: relative;
    bottom: 1px;
  }
`;

const StyledInputRow = styled(InputRow)`
  margin-bottom: 0 !important;
  display: flex;
  flex-direction: column;
`;

const EmailSignup = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 auto ${pxToRem(30)} auto;

  ${media.mvp} {
    margin-bottom: ${pxToRem(21)};
    flex-direction: column;
  }

  .text-input {
    width: 519px !important;
    background: #fdfdfd;
    border: 0.5px solid #17171d;
    border-radius: 4px;
    font-style: normal;
    font-weight: 325;
    font-size: ${pxToRem(16)};
    line-height: ${pxToRem(22)};
    height: 40px;

    ${media.mvp} {
      width: 100%;
      max-width: 100%;
    }
  }

  .email-button {
    margin-left: ${pxToRem(17)};
    width: 222px;
    height: 40px;
    font-size: ${pxToRem(12)};

    ${media.mvp} {
      margin-left: 0;
      width: 100%;
      margin-top: ${pxToRem(13)};
    }
  }
`;

const Disclaimer = styled.div`
  font-style: normal;
  font-weight: 325;
  font-size: ${pxToRem(9)};
  line-height: ${pxToRem(11)};
  text-align: center;
  max-width: 454px;
  margin: 0 auto;

  ${media.mvp} {
    font-size: ${pxToRem(7)};
    max-width: 314px;
  }
`;

function SignUp() {
  const [{ isNative, isIframe }] = useGlobalState();
  const { mutateAsync: signupUser } = useSignup();
  const { resolvedTheme: theme } = useTheme();

  const [submitButtonState, setDisableSubmitButton] = useState(true);
  const [successState, setSuccessState] = useState(false);

  {
    /* Hide womens game on MML web and app versions. Micro site only */
  }
  const hideWbcg = isNative || isIframe;
  const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const formik = useFormik({
    initialStatus: "idle",
    initialValues: {
      email: "",
    },
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: ({ email }) => {
      signupUser(email).then(() => {
        setSuccessState(true);
        formik.resetForm({
          values: {
            email: "",
          },
        });
      });
    },
    validate: (values) => {
      const errors: Partial<typeof values> = {};
      if (!values.email || !isValidEmail.test(values.email.trim())) {
        errors.email = "Please provide a valid email address.";
        return errors;
      }

      setDisableSubmitButton(!isValidEmail.test(values.email));
    },
  });

  return (
    <>
      {!isNative && !isIframe && <Header />}
      <SignUpContentContainer>
        <BCGLogoContainer $hideWbcg={hideWbcg}>
          {theme === "dark" ? <MBCGLogoDark className="bcg-logo" /> : <MBCGLogoLight className="bcg-logo" />}
          {!hideWbcg ? (
            theme === "dark" ? (
              <WBCGLogoDark className="bcg-logo" />
            ) : (
              <WBCGLogoLight className="bcg-logo" />
            )
          ) : null}
        </BCGLogoContainer>
        {!successState ? (
          <>
            <h2>Brackets Are Coming!</h2>
            <p>Enter your email and we&apos;ll send you a reminder when BRACKETS go live in FEBRUARY.</p>
          </>
        ) : (
          <>
            <h2>SUCCESS!</h2>
            <p className="is-success">
              You&apos;re in. we&apos;ll send you a reminder when BRACKETS go live in FEBRUARY.
            </p>
          </>
        )}
        <form id="ncaa-play-email-signup" onSubmit={formik.handleSubmit}>
          <EmailSignup>
            <StyledInputRow>
              <Input
                id="email"
                name="email"
                disabled={successState}
                placeholder="Enter Email"
                className={`text-input ${formik.errors.email && formik.touched.email && "error"}`}
                aria-label="Enter email"
                autoComplete="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
              />
              <StyledInputError $shouldShow={formik.errors.email && formik.touched.email}>
                {formik.errors.email}
              </StyledInputError>
            </StyledInputRow>
            <MMLButton
              $primary
              disabled={submitButtonState || successState || formik.isSubmitting}
              className="email-button"
              type="submit"
            >
              Notify Me
            </MMLButton>
          </EmailSignup>
        </form>
        <Disclaimer>
          By entering your email and clicking notify me, you are agreeing to let us send you a marketing message when
          the Capital One NCAA March Madness Bracket Challenge is open for registration.
        </Disclaimer>
      </SignUpContentContainer>
    </>
  );
}

SignUp.getLayout = function getLayout(page: ReactElement) {
  return <LandingLayout>{page}</LandingLayout>;
};

export default SignUp;
