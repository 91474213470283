import UtilityBar from "@components/dashboard/utility-bar";

function Header() {
  return (
    <>
      <UtilityBar />
    </>
  );
}

export default Header;
