// import CapitalOneLogo from "@components/icons/branding/svg-cap-one.svg";
import { media } from "@styles/styleUtils";
import styled from "styled-components";

const UtilityBarContainer = styled.div`
  background: #132e70;
  height: 42px;
  width: 100%;

  ${media.mvp} {
    display: none;
  }
`;

const UtilityBar = styled.div`
  max-width: 1380px;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  font-size: 1.3rem;
  color: #fff;
  vertical-align: middle;
  justify-content: flex-end;

  a {
    color: #fff;
    text-decoration: none;
  }

  div {
    > p {
      display: inline-block;
      position: relative;
      bottom: -11px;
    }

    &:first-child {
      margin-right: auto;
      width: auto;
    }
  }
`;

// const PresentedByContainer = styled.div`
//   display: flex;

//   .logo {
//     width: 90px;
//     height: 31px;
//     display: inline-block;
//     margin: 6px 10px 0 15px;
//   }

//   p {
//     line-height: 1.25;
//     margin: 18px 0 10px 0;
//     font-size: 0.8rem;

//     &:first-child {
//       margin-top: 23px;
//     }
//   }
// `;

function UtilityBarComponent() {
  return (
    <>
      <UtilityBarContainer>
        <UtilityBar>
          <div>
            <p>
              <a href="https://www.ncaa.com/?cid=bcg_nav" target="_blank" rel="noreferrer noopener">
                Visit NCAA.com
              </a>{" "}
              |{" "}
              <a href="https://ncaa.com/march-madness-live/watch?" target="_blank" rel="noreferrer noopener">
                Watch Games
              </a>
            </p>
          </div>
          {/* <PresentedByContainer>
            <p>Presented By</p>
            <CapitalOneLogo className="logo" />
            <p>
              Capital One is the Official Bank
              <br />
              and Credit Card of the NCAA®
            </p>
          </PresentedByContainer> */}
        </UtilityBar>
      </UtilityBarContainer>
    </>
  );
}

export default UtilityBarComponent;
