import { useMutation } from "@tanstack/react-query";
import useApiFetch, { dynamicApiEndpoint } from "./useApiFetch";

/**
 * Hook to access POST a users email to sign up
 */
export default function useSignup() {
  const { fetchApiEndpoint } = useApiFetch();

  function signupUser(email) {
    return fetchApiEndpoint(`${dynamicApiEndpoint}/prereg-subscribe.json`, {
      method: "POST",
      body: JSON.stringify({
        email,
      }),
    });
  }

  return useMutation({ mutationFn: signupUser });
}
